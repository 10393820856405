document.addEventListener('DOMContentLoaded', () => {
	/**
	 * Remove preload
	 */
	document.getElementsByTagName("BODY")[0].classList.remove('preload');
	/**
	 * IE support for object-fit
	 */
	objectFitImages();
});

//Lottie stuff

const playerContainers = document.querySelectorAll(".hoverEffects");
playerContainers.forEach(container => {
	container.addEventListener("mouseover", () => {
		const player = container.querySelector("lottie-player");
		player.setDirection(1);
		player.play();
	});

	container.addEventListener("mouseleave", () => {
		const player = container.querySelector("lottie-player");
		player.setDirection(-1);
		player.play();
	});
});

$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if (scroll >= 125) {
				$("nav").addClass("darkHeader");
		} else {
				$("nav").removeClass("darkHeader");
		}
});

$(document).on('click', 'a[href^="#"]', function(e) {
		// target element id
		var id = $(this).attr('href');

		// target element
		var $id = $(id);
		if ($id.length === 0) {
				return;
		}

		// prevent standard hash navigation (avoid blinking in IE)
		e.preventDefault();

		// top position relative to the document
		var pos = $id.offset().top;

		// animated top scrolling
		$('html, body').animate({
			scrollTop: pos
		},1000);
});



/* Trigger Stuff */

$( ".togglemobnav" ).click(function() {
  event.preventDefault();
  $( ".hamburger" ).toggleClass("is-active");
	$( ".mobile-navigation" ).toggleClass("active");
	$( ".darkHeader" ).toggleClass("mobactive");
  $( ".logo-on-mobile-nav" ).toggleClass("active");
	$( "body" ).toggleClass("mobile-nav-toggle");
	$( ".nav-company-name" ).toggleClass("text-on-mobile-nav");
});


$( ".toggle-investor" ).click(function() {
  event.preventDefault();
  $( ".investor-login" ).toggleClass("active");
});

$( ".remove-menu-and-toggle-investor" ).click(function() {
  event.preventDefault();
	$( ".hamburger" ).toggleClass("is-active");
	$( ".mobile-navigation" ).toggleClass("active");
	$( ".darkHeader" ).toggleClass("mobactive");
	$( "body" ).toggleClass("mobile-nav-toggle");
	$( ".logo-on-mobile-nav" ).toggleClass("active");
	$( ".investor-login" ).toggleClass("active");
});


$( ".sub-toggler" ).click(function() {
  event.preventDefault();
	$( ".sub-toggler" ).toggleClass("active");
	$( "ul.sub" ).slideToggle( 200 );
});



const sections = document.querySelectorAll(".anchor");
const navLi = document.querySelectorAll(".press-subnav li a");
window.onscroll = () => {
  var current = "";

  sections.forEach((section) => {
    const sectionTop = section.offsetTop;
    if (pageYOffset >= sectionTop - 60) {
      current = section.getAttribute("id"); }
  });

  navLi.forEach((li) => {
    li.classList.remove("active");
    if (li.classList.contains(current)) {
      li.classList.add("active");
    }
  });
};












//
